import 'core-js';
import './assets/css/style.scss';
import ContactForm from './assets/js/contactForm';
import Testimonials from './assets/js/testimonials';
import Header from './assets/js/header';
import HeaderU from './assets/js/header_u';
import Footer from './assets/js/footer';
import AgencyMap from './assets/js/agencyMap';
import PartnersPage from './assets/js/partnersPage';
import ErrorPage from './assets/js/errorPage';
import UseCaseSearch from './assets/js/useCaseSearch';
import UseCaseLatest from './assets/js/useCaseLatest';
import NewsArchive from './assets/js/newsArchive';
import UseCase from './assets/js/useCase';
import PublicationsArchive from './assets/js/publicationsArchive';
import TestimonialJobList from './assets/js/testimonialJobList';
import TestimonialsTimeline from './assets/js/testimonialsTimeline';
import Animsition from './assets/js/animsition';
import JobForm from './assets/js/jobForm';
import JobList from './assets/js/jobList';
import NumberBlock from './assets/js/numberBlock';
import GtmEvents from './assets/js/gtmEvents';
import WebinarRegistrationForm from './assets/js/webinarRegistrationForm';
import WebinarArchive from './assets/js/webinarArchive';
import VideoGallery from './assets/js/videoGallery';
import HighlightCode from './assets/js/highlightCode';
import sliderTestimonialQuotes from './assets/js/testimonialQuotes';
import partnersFilter from './assets/js/partnersFilter';
import textQuoteSlider from './assets/js/textQuoteSlider';

const init = () => {
    const GLOBALS = {
        $document: document,
        $body: document.body,
        $window: window,
    };
    [
        {
            component: ContactForm,
            className: 'contactForm',
        },
        {
            component: Testimonials,
            className: 'testimonials',
        },
        {
            component: Header,
            className: 'mainHeader',
        },
        {
            component: HeaderU,
            className: 'mainHeader--u',
        },
        {
            component: Footer,
            className: 'mainFooter',
        },
        {
            component: AgencyMap,
            className: 'mapAgency',
        },
        {
            component: PartnersPage,
            className: 'partnersPage',
        },
        {
            component: ErrorPage,
            className: 'errorPage',
        },
        {
            component: UseCaseSearch,
            className: 'useCaseSearch',
        },
        {
            component: UseCaseLatest,
            className: 'useCaseLatest',
        },
        {
            component: NewsArchive,
            className: 'newsArchivePage',
        },
        {
            component: UseCase,
            className: 'usecaseDetail',
        },
        {
            component: PublicationsArchive,
            className: 'publicationsArchivePage',
        },
        {
            component: TestimonialJobList,
            className: 'testimonialSlider',
        },
        {
            component: TestimonialsTimeline,
            className: 'testimonialsTimeline',
        },
        {
            component: Animsition,
            className: 'useCaseLatest',
        },
        {
            component: JobForm,
            className: 'jobForm',
        },
        {
            component: JobList,
            className: 'jobList',
        },
        {
            component: NumberBlock,
            className: 'numberBlock__list',
        },
        {
            component: GtmEvents,
            className: 'gtmEvents',
        },
        {
            component: WebinarRegistrationForm,
            className: 'webinarRegistrationForm',
        },
        {
            component: WebinarArchive,
            className: 'webinarArchivePage',
        },
        {
            component: VideoGallery,
            className: 'videoGallery__videoList',
        },
        {
            component: HighlightCode,
            className: 'highlight__code',
        },
        {
            component: sliderTestimonialQuotes,
            className: 'sliderTestimonialQuotes',
        },
        {
            component: partnersFilter,
            className: 'partnersFilter',
        },
        {
            component: textQuoteSlider,
            className: 'textQuoteSlider',
        },
    ].forEach((obj) => {
        if (obj.className) {
            // search dom element with className of component
            const listDomElem = document.getElementsByClassName(obj.className);
            // create instance for each dom elem with className of component
            for (let i = 0; i < listDomElem.length; i += 1) {
                const factory = new obj.component(GLOBALS, listDomElem[i]); // eslint-disable-line new-cap
                factory.init();
            }
        }
    });
};

document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
        init();
    }
};
